<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">About Us</h4>
                                </div>

                                <div v-if="dataFetch">
                                    <p>Loading...</p>
                                </div>
                                
                                <form v-if="!dataFetch" v-on:submit.prevent="onSubmit">
                                    <div class="row">
                                        <input type="hidden" name="id" v-model="form.id">
                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Story in Brief</label>
                                            <ckeditor v-model="form.story" :config="editorConfigStory"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Vision</label>
                                            <ckeditor v-model="form.vision" :config="editorConfigVision"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Mission</label>
                                            <ckeditor v-model="form.mission" :config="editorConfigMission"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Core Values Description</label>
                                            <ckeditor v-model="form.core_values_description" :config="editorConfigCoreValues"></ckeditor>
                                        </div>

                                        <div class="form-group col-md-12 mt-3">
                                            <label for="exampleInputEmail1">Core Values Item</label>
                                            <div class="text-center mt-3" v-if="dataFetchItem">
                                                Loading...
                                            </div>
                                            <div class="table-responsive" v-if="!dataFetchItem">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style="width:5%">No</th>
                                                            <th>Name</th>
                                                            <th>Description</th>
                                                            <th style="width:25%">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in data_core_values" :key="index">
                                                            <td class="text-center">{{ ++index }}</td>
                                                            <td>{{ item.name }}</td>
                                                            <td v-html="item.description">
                                                            </td>
                                                            <td>
                                                                <div class="button-items">
                                                                    <button data-toggle="modal" data-target="#modalCoreValues" type="button" class="btn btn-primary btn-sm rounded" v-on:click="EditItem(item)"><i class="fas fa-edit"></i> Edit</button>
                                                                    <button type="button" class="btn btn-danger btn-sm rounded" v-on:click="deletedItem(item)"><i class="fas fa-trash"></i> Delete</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colspan="4">
                                                                <button data-toggle="modal" data-target="#modalCoreValues" type="button" class="btn btn-primary btn-sm rounded" v-on:click="addItem()"><i class="fas fa-plus"></i> Add Item</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Cover Picture</label>
                                            <input type="file" class="form-control" name="cover_picture" id="cover_picture" @change="previewCoverPicture" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_cover_picture">
                                            <label>Preview Cover Picture</label>
                                            <div class="cover_picture mb-2">
                                                <div class="thumb">
                                                    <a :href="preview_cover_picture" data-lightbox="1" target="blank">
                                                        <img :src="preview_cover_picture" alt="" class="img-fluid img-cover_picture" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm" type="button" v-on:click="removeBanner('cover_picture')"><i class="fa fa-trash"></i> Remove Cover Picture</button>
                                        </div>

                                        <div class="form-group col-md-6 mt-3">
                                            <label>Team Picture</label>
                                            <input type="file" class="form-control" name="team_picture" id="team_picture" @change="previewTeamPicture" accept="image/png, image/gif, image/jpeg">
                                        </div>

                                        <div class="form-group col-md-6 mt-3" v-if="preview_team_picture">
                                            <label>Preview Team Picture</label>
                                            <div class="team_picture mb-2">
                                                <div class="thumb">
                                                    <a :href="preview_team_picture" data-lightbox="1" target="blank">
                                                        <img :src="preview_team_picture" alt="" class="img-fluid img-team_picture" style="width:50%">
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger btn-sm" type="button" v-on:click="removeBanner('team_picture')"><i class="fa fa-trash"></i> Remove Team Picture</button>
                                        </div>
                                    </div>

                                    <div class="button-items mt-3">
                                        <button type="submit" class="btn btn-primary" :disabled="waiting">
                                        <i class="fa fa-save"></i> {{ waiting ? 'Please wait...' : 'Save' }}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalCoreValues" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> {{ form_core_values.id ? 'Change Item':'Add Item' }}</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmitCoreValues">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="mt-3">
                                        <label>Item Name</label>
                                        <input type="text" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Enter item name..." v-model="form_core_values.name">
                                    </div>

                                    <div class="mt-3">
                                        <label>Item Description</label>
                                        <ckeditor v-model="form_core_values.description" :config="editorConfigDescription"></ckeditor>                                        
                                    </div>

                                    <hr/>
                                    <div class="mt-3">
                                        <button :disabled="waiting" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting ? 'Please wait...' : 'Save Item' }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>

    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'AboutPage',
        metaInfo: {
            title: 'About Us',
            titleTemplate: '%s - Tata Hijau Asri'
        },
        created() {
            this.getData();
            this.getItem();
        },
        data() {
            return {
                label           : 'Add',
                form : {
                    id              : "",
                    story           : "",
                    vision          : "",
                    mission                 : "",
                    core_values_description : "",
                    phone                   : "",
                    email           : "",
                    address         : "",
                    facebook_name   : "",
                    facebook_url    : "",
                    instagram_name  : "",
                    instagram_url   : "",
                    team_picture               : "",
                    previous_team_picture      : "" ,
                    cover_picture              : "",
                    previous_cover_picture     : "" ,
                },
                data_core_values : [],
                form_core_values: {
                    id : '',
                    name : '',
                    description : ''
                },
                preview_team_picture   : '',
                preview_cover_picture   : '',
                editorConfigStory: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigVision: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigMission: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigCoreValues: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                editorConfigDescription: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',                    
                },
                dataFetch       : false,
                dataFetchItem   : true,
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                phone: {
                    required
                },
                email: {
                    required
                },
                address: {
                    required
                },
                facebook_name: {
                    required
                },
                facebook_url: {
                    required
                },
                instagram_name: {
                    required
                },
                instagram_url: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('story', this.form.story);
                    formData.append('vision', this.form.vision);
                    formData.append('mission', this.form.mission);
                    formData.append('core_values_description', this.form.core_values_description);
                    formData.append('phone', this.form.phone);
                    formData.append('email', this.form.email);
                    formData.append('address', this.form.address);
                    formData.append('facebook_name', this.form.facebook_name);
                    formData.append('facebook_url', this.form.facebook_url);
                    formData.append('instagram_name', this.form.instagram_name);
                    formData.append('instagram_url', this.form.instagram_url);
                    formData.append('maps', this.form.maps);
                    formData.append('team_picture', this.form.team_picture);
                    formData.append('cover_picture', this.form.cover_picture);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/identity/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'about_us' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            onSubmitCoreValues: function () {
                this.waiting    = true;
                this.submitted  = true;

                if (!this.form_core_values.name && !this.form_core_values.description) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form_core_values.id);
                    formData.append('name', this.form_core_values.name);
                    formData.append('description', this.form_core_values.description);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/identity/save-item', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        document.getElementById("btnCloseModal").click();                                        
                                        this.getItem();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getData: function(id) {
                let uri     = process.env.VUE_APP_APIHOST+'admin/identity/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse             = res.data.data;
                        this.form                   = getResponse;
                        this.preview_team_picture   = getResponse.team_picture;
                        this.preview_cover_picture   = getResponse.cover_picture;
                        this.dataFetch              = false;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'about_us' });
                            }
                        });
                    }
                });
            },
            getItem: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/identity/core-values-item';

                this.dataFetchItem  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        let getResponse             = res.data.data;
                        this.data_core_values       = getResponse;
                        this.dataFetchItem          = false;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'about_us' });
                            }
                        });
                    }
                });
            },
            previewTeamPicture(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_team_picture = URL.createObjectURL(file);
                    this.form.team_picture = file;
                }
            },
            previewCoverPicture(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 2) {
                    alert("Uploaded files cannot be more than 2 MB");
                } else {
                    this.preview_cover_picture = URL.createObjectURL(file);
                    this.form.cover_picture = file;
                }
            },
            removeBanner: function(params="") {
                this.$swal({
                    icon: 'info',
                    title: 'Remove picture',
                    text: 'Are you sure you want to remove this picture?',
                    confirmButtonText: 'Remove',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {

                    if(params == 'cover_picture') {
                        this.preview_cover_picture = '';
                        this.form.cover_picture = '';
                    }

                    if(params == 'team_picture') {
                        this.preview_team_picture = '';
                        this.form.team_picture = '';
                    }
                }
                });
            },
            addItem: function() {
                this.form_core_values = {
                    id : '',
                    name : '',
                    description : ''
                }
            },
            EditItem: function(item) {
                this.form_core_values = {
                    id : item.id,
                    name : item.name,
                    description : item.description
                }
            },
            deletedItem: function (item) {
                this.$swal({
                    icon: 'info',
                    title: 'Delete data',
                    text: 'Are you sure you want to delete item: '+item.name+'?',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/identity/delete-item', {
                        id: item.id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getItem();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>